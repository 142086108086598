module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Start"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://iycf-production-wordpress.swacedigital.com/graphql","html":{"createStaticFiles":false,"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":4000,"hardCacheData":false},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"type":{"Post":{"exclude":true},"Comment":{"exclude":true},"Tag":{"exclude":true},"Category":{"exclude":true},"MediaItem":{"createFileNodes":false,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"DocumentAuthor":{"excludeFieldNames":["contentNodes","seo","documents"]},"DocumentContentType":{"excludeFieldNames":["contentNodes","seo","documents"]},"DocumentCountry":{"excludeFieldNames":["contentNodes","seo","documents"]},"DocumentIYCFTopic":{"excludeFieldNames":["contentNodes","seo","documents"]},"DocumentKeyword":{"excludeFieldNames":["contentNodes","seo"]},"DocumentLanguage":{"excludeFieldNames":["contentNodes","seo","documents"]},"DocumentPublisher":{"excludeFieldNames":["contentNodes","seo","documents"]},"DocumentRegion":{"excludeFieldNames":["contentNodes","seo","documents"]},"DocumentTargetAudience":{"excludeFieldNames":["contentNodes","seo","documents"]},"DocumentTopic":{"excludeFieldNames":["contentNodes","seo"]},"DocumentUserChallenge":{"excludeFieldNames":["contentNodes","seo","documents"]},"DocumentLicense":{"excludeFieldNames":["contentNodes","seo","documents"]},"Document":{"excludeFieldNames":["seo"]},"RootQuery":{"excludeFieldNames":["WpParent","WpChildren","contentType"]}},"schema":{"timeout":72000000,"requestConcurrency":50,"previewRequestConcurrency":50,"perPage":50,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/resource-centre-theme/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-JZ7GHZW82J"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"NetworkFirst"}]}},
    },{
      plugin: require('../node_modules/resource-centre-theme/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"/opt/build/repo/iycf/../wordpress/uploads/","basePath":"/"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
